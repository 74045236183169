import Vue from 'vue'
import EventHub from '../util/EventHub'

export default Vue.extend({
  methods: {
    onBackKeyDown () {
      this.$xp.history.back()
    },
    onActivityPause () {
      EventHub.Bus.$emit(EventHub.TYPES.ANDROID_ACTIVITY_PAUSE)
    },
    async init () {
      console.log('----------', this.$xp)
      document.addEventListener('pause', this.onActivityPause, false)
      document.addEventListener('backbutton', this.onBackKeyDown, false)
      this.$xp.battery.init()
      this.$xp.network.startListening()
      this.$xp.screen.setBrightness()
      if (this.$xp.device.platform === 'ios') {
        window.StatusBar.hide()
        window.addEventListener('statusTap', () => {
          window.StatusBar.isVisible ? window.StatusBar.hide() : console.log('it should be visible but its not')
        }, false)
      }
      try {
        await this.$xp.device.checkPermissions()
      } catch (e) {
        console.log('checkPermission error')
        console.warn(e)
      }
      if (this.$xp.settings.mergedSettings.radio.wifi.alwaysOn) {
        this.$xp.wifi.lockWifiOn('xpedeo')
      }
      // window.addEventListener('message', this.onIFrameCommand, false)}
    },
    initNfc () {
      this.$watch('$xp.settings.mergedSettings.radio.nfcActive', {
        immediate: true,
        handler (value) {
          if (this.$xp.device.platform !== 'browser') {
            if (value !== this.$xp.nfc.isEnabled) {
              if (value) {
                this.$xp.nfc.enable()
              } else {
                this.$xp.nfc.disable()
              }
            }
          }
        }
      })
    },
    async initEva () {
      await this.$xp.device.getUuid()
    }
  },
  created () {
    this.init()
    this.$xp.nfcInstalled && this.initNfc()
    if (this.$xp.device.isLoan) {
      const shouldRestoreHistory = window.localStorage.getItem('shouldRestoreHistory')
      if (shouldRestoreHistory === 'true') {
        console.log('restoring history from local storage')
        this.$xp.history.restoreHistoryState()
        this.$xp.settings.restoreSettingsState()
        window.localStorage.setItem('shouldRestoreHistory', false)
        this.$xp.history.back()
      }
    }
    this.$xp.evaInstalled && this.initEva()
  },
  watch: {
    '$xp.settings.mergedSettings.isWifiRequired': {
      handler (newVal) {
        console.log('isWifiRequired changed: ' + newVal)
        if (this.$xp.device.platform === 'android') {
          if (newVal) {
            this.$xp.wifi.lockWifiConnectionOn('page')
          } else {
            this.$xp.wifi.releaseWifiConnectionOn('page')
          }
        }
      }
    },
    '$xp.settings.mergedSettings.screen.brightness': {
      handler (newValue) {
        this.$xp.screen.setBrightness(newValue)
      }
    },
    '$xp.settings.mergedSettings.theme': {
      immediate: true,
      handler (newVal) {
        document.querySelector('html').className = newVal
      }
    },
    '$xp.media.isMediaPlaying': {
      handler (value) {
        if (!this.$xp.device.isLoan && this.$xp.device.platform !== 'browser') {
          if (value) {
            // keepAwake
            window.plugins.insomnia.keepAwake(
              () => { console.log('keepAwake success') },
              (error) => { console.log('keepAwake ERROR', error) }
            )
          } else {
            // allowSleepAgain
            window.plugins.insomnia.allowSleepAgain(
              () => { console.log('allowSleepAgain success') },
              (error) => { console.log('allowSleepAgain ERROR', error) }
            )
          }
        }
      }
    }
  },
  render (h) {
    // <div id="app" :class="{
    // 'c-app': true,
    // 'c-app--rtl': $xp.polyglot.rtl,
    // 'c-app--is-loan': $xp.device.isLoan,
    // [`c-app--${$xp.device.platform}`]: true,
    // 'c-app--safari': $xp.device.isIPhoneOrPad,
    // [`c-app--${$xp.screen.orientation}`]: true,
    // 'c-app__ios-safari-navbar': $xp.device.platform === 'browser' && $xp.device.isIPhoneOrPad && $xp.screen.isBrowserNavBarVisible
    // }">
    //   <router-view></router-view>
    //   <div v-if="this.$xp.navigation.isEventConsumerEnabled" class="c-app__event-consumer"></div>
    //   <network-error-screen v-if="!$xp.network.isOnline && !$xp.device.isLoan"></network-error-screen>
    // </div>
    return h(
      'div',
      {
        class: {
          'c-app': true,
          'c-app--rtl': this.$xp.polyglot.rtl,
          'c-app--is-loan': this.$xp.device.isLoan,
          [`c-app--${this.$xp.device.platform}`]: true,
          'c-app--safari': this.$xp.device.isIPhoneOrPad || this.$xp.device.isSafari,
          'c-app--safari-ios': this.$xp.device.isIPhoneOrPad && this.$xp.device.isSafari,
          [`c-app--${this.$xp.screen.orientation}`]: this.$xp.screen.orientation,
          'c-app__ios-safari-navbar': this.$xp.device.platform === 'browser' && this.$xp.device.isIPhoneOrPad && this.$xp.screen.isBrowserNavBarVisible,
          'c-app--ios-notch': this.$xp.device.platform === 'ios' && this.$xp.content.pageData.Class !== 'XpSplashScreen' // for ios notch, workaround (splash screen resets padding somehow, so this class is added and removed accordingly in pagetitle watcher in pagecontainer)
        }
      },
      [
        h('router-view'),
        this.$xp.navigation.isEventConsumerEnabled || this.$xp.http.isLoadingPageJson ? h('div', { class: 'c-app__event-consumer' }) : null
      ]
    )
  }
})
